<template>
  <div class="navbar">
    <a href="#info" class="navbarlink">Cerimonia</a>
    <a href="#storia" class="navbarlink">Su Di Noi</a>
    <a href="#home" class="navbarlink handwriting">Jessica <img src="../assets/heart.webp" width="25"/> Tito</a>
    <a href="#viaggio" class="navbarlink">Viaggio</a>
    <a href="#contattaci" class="navbarlink">Contattaci</a>
  </div>
  <div class="menubutton" @click="toggleMenu()">
    <img src="../assets/Hamburger_icon.svg.png" style="width:40px;"/>
  </div>
  <div class="navlist" :style="{width: activeMenu ? '150px' : '0px'}">
    <a href="#home" class="menulink" v-if="activeMenu" @click="closeMenu()">Home</a>
    <a href="#info" class="menulink" v-if="activeMenu" @click="closeMenu()">Cerimonia</a>
    <a href="#storia" class="menulink" v-if="activeMenu" @click="closeMenu()">Su Di Noi</a>
    <a href="#viaggio" class="menulink" v-if="activeMenu" @click="closeMenu()">Viaggio</a>
    <a href="#contattaci" class="menulink" v-if="activeMenu" @click="closeMenu()">Contattaci</a>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data(){
    return{
    };
  },
  computed:{
    activeMenu(){
      return this.$store.state.activeMenu;
    }
  },
	methods:{
		toggleMenu: function(){
			this.$store.state.activeMenu = !this.$store.state.activeMenu;
		},
		closeMenu: function(){
			if (this.$store.state.activeMenu){this.$store.state.activeMenu = false}
		}
	}
}
</script>

<style scoped>
  .menulink{font-family: 'Poppins', sans-serif; display:block; margin: 10px auto; width:100px; color: white; text-decoration:none;}
  .menubutton{display:none;z-index: 1;}
  .navlist{padding-top: 10px; position: fixed; background-color: #00cccc; top: 0px; left:0px; transition: .5s; overflow-x:hidden; height: 100%;z-index: 1;}
  .navbar{
    display: inline;
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: #0cc;
  }
  .navbarlink{color: black; margin-left: 20px; text-decoration:none; line-height: 80px; font-family: 'Poppins', sans-serif; margin-right: 20px;}
  .handwriting{font-family: 'Niconne', cursive !important; font-size: 30px;}

  @media only screen and (max-width: 1024px) {
    .menubutton{display: inline; position:fixed; top: 10px; left: 10px; cursor:pointer}
    .navbar{display:none;}
  }
</style>
