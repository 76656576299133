<template>
  <div class="content" @click="closeMenu()">
    <div id="footer" class="anchor"></div>
    <div class="contentbox">
      <p>Made with <img src="/img/heart.c5d4f492.webp" width="12" data-v-41458b80=""> by Tito</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {  };
  }
}
</script>

<style scoped>
  .content{
    padding-top:100px;
    font-style: italic;
  }
  .contentbox{
    height: 50px;
    text-align:center;
  }
  .contentbox p{
    margin:0;
  }

  @media only screen and (max-width: 1024px) {

  }

</style>
