<template>
    <Navbar/>
    <Home/>
    <Info/>
    <Storia/>
    <Viaggio/>
    <Contattaci/>
    <Footer/>
    <Churchinfo/>
    <Locationinfo/>
</template>

<script>
import Navbar from './components/Navbar'
import Home from './components/Home'
import Info from './components/Info'
import Storia from './components/Storia'
import Churchinfo from './components/Churchinfo'
import Locationinfo from './components/Locationinfo'
import Footer from './components/Footer'
import Viaggio from './components/Viaggio'
import Contattaci from './components/Contattaci'

export default {
  name: 'MainPage',
  components: {
    Navbar, Home, Info, Storia, Locationinfo, Churchinfo, Footer, Viaggio, Contattaci
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Niconne&family=Poppins&family=Merienda+One&display=swap');

  @font-face {
    font-family: 'Vivaldi';
    src:  url('./assets/Vivaldi.woff2') format('woff2'),
          url('./assets/Vivaldi.woff') format('woff');
  }

  html {scroll-behavior: smooth;}
  body {margin:0;}
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  .content{
    background-color: #b9ecec;
  }
  .contentbox h1{
    padding:15px;
    line-height: 10px;
    background-color: #00cccc;
    text-align:center;
    font-size: 30px;
    color: white;
    font-family: 'Niconne', cursive !important;
    font-weight: 400;
    margin:0;
  }
  .noscroll{
    overflow: hidden;
  }

  @media only screen and (max-width: 1024px) {
    .content{
      padding-top: 50px !important;
    }
    .contentbox{
      padding-top:0px;
    }
  }

</style>
