<template>
  <div class="content" @click="closeMenu()">
    <div id="contattaci" class="anchor"></div>
    <div class="contentbox">
      <p>Vuoi scriverci per informazioni, commenti o altro?</p>
      <p class="clicktext" @click="show = !show">Clicca qui per vedere i nostri contatti</p>
      <div v-if="show" class="showtext">
        <p>Jessica: <a href="mailto:jessica.ljajic@yahoo.it">jessica.ljajic@yahoo.it</a><br> 3465146253</p>
        <p>Tito: <a href="mailto:tito.gnecchi@tiscali.it">tito.gnecchi@tiscali.it</a><br>3401715300</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contattaci',
  data () {
    return {
      show: false
    };
  }
}
</script>

<style scoped>
  .content{
    padding-top:80px;
  }
  .contentbox{
    text-align:center;
    display: inline-block;
    font-family: 'Niconne';
    font-size: 30px;
  }
  #contattaci{
    position:relative;
    top: -100px;
  }
  .clicktext{
    cursor: pointer;
    color: chocolate;
  }
  .showtext a{
    text-decoration:none;
  }

  @media only screen and (max-width: 1024px) {
    #home{
      padding-top:0;
    }
  }

</style>
