<template>
  <div class="content" @click="closeMenu()">
    <div id="home" class="anchor"></div>
    <div class="contentbox">
      <div class="goldlineimg"></div>
      <img class="jetimg" src="../assets/JeT1.png"/>
      <div class="hometext pos1">
        <p>Jessica</p>
        <img class="ringsimg" src="../assets/rings.png">
        <p>Tito</p>
      </div>
      <p class="hometext pos2">Finalmente il momento è arrivato, ci sposiamo!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {  };
  },
  methods:{
    closeMenu: function(){
      this.$store.commit('closeMenu');
    }
  }
}

</script>

<style scoped>
  .content{
    padding-top:140px;
    display: inline-block;
  }
  .contentbox{
    position: relative;
    font-family: 'Vivaldi';
    width: 100%;
    height: auto;
    display: inline-block;
    margin-bottom: 80px;
    /* font-family: 'Niconne', cursive !important; */
  }
  .hometext{
    float: left;
    transform: rotate(-9deg);
  }
  .pos1{
    font-size: 7vw;
  }
  .pos1 p{
    float: left;
    margin: 0 20px;
  }
  .pos2{
    font-size: 5vw;
    max-width: 50%;
  }
  .jetimg{
    height: auto;
    width: 40%;
    max-width: 500px;
    float: left;
    position: relative;
    margin-left: 10%;
  }
  .goldlineimg{
    margin-top: 9vw;
    width: 101%;
    position: absolute;
    top: 75px;
    left:-6px;
    background-color: white;
    height: 7px;
    transform: rotate(-10deg);
  }
  .ringsimg{
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    width: 7vw;
  }
  #home{
    position: absolute;
    top: 0;
  }

  @media only screen and (max-width: 1024px) {
    .goldlineimg{
      display:none;
    }
    .jetimg{
      margin-left: 0;
      top: 20vw;
      width:70%;
      float: none;
    }
    .ringsimg{
      width: 16vw;
      margin-right: -10px;
      margin-left:0;
      float:none;
      margin-top: 0;
      vertical-align: sub;
    }
    .pos1{
      font-size: 15vw;
      position: absolute;
      top: 0;
      float: none;
      margin: 0;
      transform: none;
      left: 0;
      right: 0;
    }
    .pos2{
      font-size: 10vw;
      position: relative;
      top: 20vw;
      margin: 0 20px;
      float: none;
      transform: none;
      max-width: 500%;
    }
    .pos1 p{
      display:inline;
      float: none;
      margin:0 15px;
    }

  }

</style>
