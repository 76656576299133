<template>
  <div class="content" @click="closeMenu()">
    <div id="storia" class="anchor"></div>
    <div class="contentbox">
      <!--<h1><span>Su Di Noi</span></h1>-->
      <div class="box" @touchstart="touchStart">
        <img src="../assets/photo1.webp" style="display:inline;"/>
        <img src="../assets/photo2.webp"/>
        <img src="../assets/photo3.webp"/>
        <img src="../assets/photo4.webp"/>
        <a class="prev" @click="changeImage(-1)">&#10094;</a>
        <a class="next" @click="changeImage(1)">&#10095;</a>
      </div>
      <p class="descrizione">Come ci siamo conosciuti?</p>
      <div class="testoprincipale">
        <p>Ci siamo visti la prima volta nel lontano 2 Aprile 2010, dopo un mese di messaggini. <br>
          Si perché ci siamo conosciuti, come tanti si conoscono oggi, sui social!
        </p>
        <div v-if="showmore">
          <p>Gnecchi era già all’avanguardia ai tempi e mi adocchiò nella foto di un’amica su Netlog (chi se lo ricorda?). <br> Mi aggiunse poi su Facebook e da lì iniziammo a chattare. </p>
          <p>Ed io cosa pensai? <br>All’inizio ero convinta fosse un altro provolone come tanti, ma poi mi dovetti ricredere perché nelle sue parole c’era solo curiosità di conoscermi, ma c’è un ma…
            <br>Non si tirava insieme! Strano vero? Così sono stata io a chiedergli il numero di cellulare e anche di incontrarci! </p>
          <p>E come si diceva in partenza, il 2/4/2010 ci incontrammo ad Arcore e galeotto fu quella giornata al parco! Sì perché da lì non ci siamo più lasciati.</p>

          <p class="headtitle"><br>Cosa dire di questi 12 anni insieme?</p>
          <p>Siamo due persone quasi opposte e se i primi anni eravamo giovani e non ci davamo peso a queste diversità, ad un certo punto abbiamo dovuto farci i conti.</p>
          <p><span style="font-family:'Niconne'; font-size: 35px;mergin-right: 4px">Tito</span> è una persona molto calma, pacato nei modi, paziente (quasi sempre), molto disponibile con tutti.</p>
          <p>E’ una persona molto concreta e razionale, che si muove con due cementi ai piedi, non si sbilancia quasi mai se prima non si sente sicuro al 98%. <br>La sua testa viaggia su mille progetti, fantasticando qua e là tra le nuvole e spesso è difficile ottenere la sua attenzione piena.</p>
          <p>E’ una persona estremamente buona, non si scompone davanti a nulla e cerca sempre delle soluzioni concrete. <br> Quello che sa fare meglio è esserci per me, sempre pronto a donarmi un abbraccio e a farmi ridere.</p>

          <p><span style="font-family:'Niconne'; font-size: 35px;mergin-right: 4px">Jessica</span> è, al contrario, molto impulsiva e focosa. Basta poco a volte per accendere la miccia e far saltare per aria tutto l’equilibrio, ma questo non è per forza una cosa negativa, perché ci permette di crescere insieme e aprire discussioni anche costruttive. </p>
          <p>E’ anche lei una sognatrice che cerca di vedere il meglio nelle cose e ha la sindrome della crocerossina: appena vede qualcuno in difficoltà è la prima a correre in soccorso e fare di tutto per farlo star meglio.</p>
          <p>Corre, corre e corre sempre, non sta ferma finché non collassa. Sinceramente non so come fa a fare così tante cose al giorno senza svenire da qualche parte!</p>
          <p>E’ comunque una persona che si ferma spesso a riflettere e cerca di porre attenzione ai dettagli, di farsi autocritica e di modificarsi per poter migliorare se stessa.</p>
          <p>Quello che sa fare meglio è spronarmi a migliorarmi e supportarmi nelle mie idee/scelte, ma soprattutto sa accendermi le giornate anche quando sono state lunghe e difficili!</p>

          <p class="headtitle"><br>Cosa amiamo fare insieme?</p>
          <p>Nel mezzo di queste diversità abbiamo trovato comunque dei modi di stare insieme senza grande sforzo. <br>Insieme ci piace vedere il mondo, esplorare sempre luoghi nuovi e tornare a casa con lo sguardo un po’ cambiato.</p>
          <p>Amiamo la natura, la bellezza che regala agli occhi e al cuore e il senso di serenità che ci trasmette. Non per niente il nostro viaggio di nozze avrà come meta l’Islanda!</p>
          <p>Non passa giorno senza abbracciarci e senza chiedere all’altro come sta. Avere cura dell’altro è prezioso per noi. Ed infine, amiamo ridere insieme, ironizzare su tutto, anche e soprattutto quando la vita ci appesantisce. E questo crediamo sia una grande salvezza.</p>

        </div>
      </div>
      <div class="showmore">
        <p @click="showmore = !showmore" v-if="!showmore">Leggi di più..</p>
        <p @click="showmore = !showmore" v-if="showmore">Chiudi</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Storia',
  data () {
    return {
      imgDisp:1,
      showmore: false
    };
  },
  methods:{
    closeMenu: function(){
      this.$store.commit('closeMenu');
    },
    changeImage: function(n){
      var i;
      var slides = document.getElementsByClassName("box")[0].getElementsByTagName('img');
      this.imgDisp += n;
      if (this.imgDisp > slides.length) {this.imgDisp = 1}
      if (this.imgDisp < 1) {this.imgDisp = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[this.imgDisp-1].style.display = "inline";
    },
    touchStart (touchEvent) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      //const posYStart = touchEvent.changedTouches[0].clientY;
      addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
    },
    touchEnd (touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if ((posXStart - posXEnd) > 50 ) {
        // swipe right
        this.changeImage(1)
      } else if ((posXStart - posXEnd) < -50) {
        // swipe left
        this.changeImage(-1)
      }
    }
  }
}
</script>

<style scoped>
  .contentbox{
    display: inline-block;
    text-align:center;
    width:100%;
    margin-top: 80px;
    background-color: lightseagreen;
  }
  #storia{

  }
  .box{
    display: inline-block;
    margin: 80px auto 10px;
  }
  .box img{
    width: 300px;
    margin: 10px 2vw;
  }
  .descrizione{
    color: white;
    font-style: normal;
    font-family: 'Niconne';
    font-size: 35px;
    margin: 10px 0 10px 0;
  }
  .testoprincipale{
    color: white;
    font-size: 18px;
    font-style: italic;
    line-height: 1.5;
    width: 75%;
    margin: auto;
  }
  .showmore{
    color: #2f4f4f;
    font-size: 20px;
    cursor: pointer;
  }
  .headtitle{
    font-style: normal;
    font-family: 'Niconne';
    font-size: 35px;
    margin: -35px 0 10px 0;
  }
  .prev, .next{
    display: none;
  }

  @media only screen and (max-width: 1024px) {

    .contentbox{
      margin-top: 50px;
    }
    .box {
      width: 100%;
      margin: auto;
      position: relative;
    }
    .box img{
      width: 100%;
      vertical-align: middle;
      display: none;
      margin: 0;
    }
    .testoprincipale{
      width: 90%;
    }
    /* Next & previous buttons */
    .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      padding: 16px;
      margin-top: -22px;
      color: black;
      font-weight: bold;
      font-size: 18px;
      transition: 0.6s ease;
      border-radius: 0 3px 3px 0;
      user-select: none;
      display: inline;
    }

    /* Position the "next button" to the right */
    .prev {
      left: 0;
      border-radius: 3px 0 0 3px;
    }

    /* Position the "next button" to the right */
    .next {
      right: 0;
      border-radius: 3px 0 0 3px;
    }

  }

</style>
