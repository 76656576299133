<template>
  <div class="moreinfo" v-if="$store.state.churchinfo">
    <div class="whiteback">
      <img class="closeimg" src="../assets/exit.png" @click="closeIMG()"/>
      <div class="content">
        <img class="parkimg" src="../assets/mappa_parcheggio.jpg" />
        <div class="buttons">
          <div class="singlebutton firstbutton"><img height="20" src="../assets/direction.png" /><a href="https://goo.gl/maps/4vvRr2MpUGFD2ReNA" target="_blank">Naviga verso la Chiesa</a></div>
          <div class="singlebutton"><img height="20" src="../assets/direction.png" /><a href="https://goo.gl/maps/LZezThRtf5BamDneA" target="_blank">Naviga verso il Parcheggio</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Churchinfo',
  data () {
    return {  };
  },
  methods:{
    closeIMG: function(){
      this.$store.state.churchinfo = false;
      document.body.classList.remove('noscroll');
    }
  }
}
</script>

<style scoped>
  .moreinfo{
    background-color:rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index:1;
  }
  .whiteback{
    background: white;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    margin: 2.5% auto;
  }
  .closeimg{
    width: 50px;
    position: relative;
    float: right;
    cursor: pointer;
  }
  .content{
    padding: 30px;
    background-color: white;
    border-radius:10px;
  }
  .parkimg{
    width: 80%;
    border-radius: 30px;
    margin-bottom: 5%;
  }
  .buttons{
    margin-left:-10px;
  }
  .buttons a{
    padding: 20px 0;
    text-decoration: none;
    color: black;
  }
  .singlebutton{
    height: auto;
    padding: 20px;
    width: 30%;
    border-radius: 10px;
    cursor: pointer;
    display: inline;
    font-size:18px;
    background-color: antiquewhite;
    font-family: 'Poppins';
  }
  .firstbutton{
    margin-right: 20px;
  }
  .buttons img{
    margin-right: 10px;
    vertical-align: middle;
  }

  @media only screen and (max-width: 1024px) {
    .whiteback{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0;
    }
    .closeimg{
      position: absolute;
      top: 0;
      right: 0;
    }
    .content{
      padding: 0;
    }
    .parkimg{
      width: 150%;
      margin: 0;
      border-radius:0;
      margin-left: -100px;
    }
    .buttons{
      margin-top: 10px;
      margin-left: 0;
    }
    .singlebutton{
      width:90%;
      display: inline-block;
      margin: 10px auto;
      padding: 20px 0;
    }
    .firstbutton{
      margin-right: 0px;
    }

  }

</style>
