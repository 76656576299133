import { createStore } from 'vuex'

export const store = createStore({
  state () {
    return {
      activeMenu: false,
      churchinfo: false,
      locationinfo: false
    }
  },
  mutations: {
    closeMenu: function(){
      if (store.state.activeMenu){store.state.activeMenu = false}
    }
  }
})
