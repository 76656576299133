<template>
  <div class="content">
    <div class="title">
      <p style="font-family:'Niconne'; font-size: 70px; margin:0px;">Diario di viaggio</p>
      <p>Islanda <img src="./assets/iceland_flag.png" width="50" style="position: relative;top: 10px;"> <br> 26 Aprile / 12 Maggio</p>
    </div>
    <div class="contentbox">
      <img src="./assets/badge.png" width="150">
      <!--<div class="box" @touchstart="touchStart">
        <img src="./assets/photo1.webp" style="display:inline;"/>
        <img src="./assets/photo2.webp"/>
        <img src="./assets/photo3.webp"/>
        <img src="./assets/photo4.webp"/>
        <a class="prev" @click="changeImage(-1)">&#10094;</a>
        <a class="next" @click="changeImage(1)">&#10095;</a>
      </div>-->
      <p class="descrizione">In corso</p>
      <div class="testoprincipale">
        <p>Siamo in viaggio!<br>Dopo aver vissuto con voi una bellisima ed indimenticabile giornata insieme, siamo partiti.</p>
        <p>Atterrati a Reykjavik, visiteremo in lungo e in largo diverse regioni dell'Islanda.</p>
        <p>Cercheremo di riportare qui tutto quello che visiteremo e vedremo, anche con qualche scatto.</p>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Footer from './components/Footer'

export default {
  name: 'Blog',
  data () {
    return {
      imgDisp:1
    };
  },
  components:{
    Footer
  },
  methods:{
    changeImage: function(n){
      var i;
      var slides = document.getElementsByClassName("box")[0].getElementsByTagName('img');
      this.imgDisp += n;
      if (this.imgDisp > slides.length) {this.imgDisp = 1}
      if (this.imgDisp < 1) {this.imgDisp = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[this.imgDisp-1].style.display = "inline";
    },
    touchStart (touchEvent) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      //const posYStart = touchEvent.changedTouches[0].clientY;
      addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
    },
    touchEnd (touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if ((posXStart - posXEnd) > 50 ) {
        // swipe right
        this.changeImage(1)
      } else if ((posXStart - posXEnd) < -50) {
        // swipe left
        this.changeImage(-1)
      }
    }
  }
}
</script>

<style scoped>
.content{
  display: inline-block;
  text-align:center;
  width:100%;
  background-color: #0cc;
  padding-bottom: 50px;
}
.title{
  color: white;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  margin: 20px 0 0px 0;

}
.box{
  display: inline-block;
  margin: 80px auto 10px;
}
.box img{
  width: 300px;
  margin: 10px 2vw;
}
.descrizione{
  color: white;
  font-style: normal;
  font-family: 'Niconne';
  font-size: 40px;
  margin: 10px 0 10px 0;
}
.testoprincipale{
  color: black;
  font-size: 18px;
  font-style: italic;
  line-height: 1.5;
  width: 75%;
  margin: auto;
}
.prev, .next{
  display: none;
}

@media only screen and (max-width: 1024px) {

  .contentbox{
    margin-top: 50px;
  }
  .box {
    width: 100%;
    margin: auto;
    position: relative;
  }
  .box img{
    width: 100%;
    vertical-align: middle;
    display: none;
    margin: 0;
  }
  .testoprincipale{
    width: 90%;
  }
  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: black;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    display: inline;
  }

  /* Position the "next button" to the right */
  .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

}

</style>
