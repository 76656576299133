import { createApp } from 'vue'
import { createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
import Main from './MainPage.vue'
import Blog from './Blog.vue'
import { store } from './store'

const app = createApp(App)

const routes = [
  { path: '/', component: Main, name: 'home'},
  { path: '/viaggio', component: Blog, name: 'viaggio'},
  { path: '/:pathMatch(.*)*', redirect: '/'}
];

const router = createRouter({
  routes,
  history: createWebHistory()
})

app.use(store)
app.use(router)

app.mount('#app')
