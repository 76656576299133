<template>
  <div class="content" @click="closeMenu()">
    <div id="viaggio" class="anchor"></div>
    <div class="contentbox">
        <img class="imgbackground" src="../assets/map.png"/>
        <p>E dopo i festeggiamenti <br> partiremo per una nuova meta... <span style='color:red'>Islanda</span>!</p>
        <div class="badge">
          <img src="../assets/badge.png"/>
          <p><a href="/viaggio">In corso...</a></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Viaggio',
  data () {
    return {  };
  },
  methods:{
    closeMenu: function(){
      this.$store.commit('closeMenu');
    }
  }
}
</script>

<style scoped>
  .content{
    padding-top:20px;
  }
  .contentbox{
    display: inline-block;
    text-align:center;
    width:100%;
    padding-top: 150px;
    position: relative;
  }
  .contentbox > p{
    position: absolute;
    top: 350px;
    left: 24%;
    text-align: left;
    font-size: 25px;
    font-style:italic;
    line-height: 40px;
  }
  .imgbackground{
  }
  .badge{
    position: absolute;
    top: 110px;
    right: 40vw;
  }
  .badge img{
    width: 60%;
    margin-right: -250%;
  }
  .badge p{
    position: relative;
    top: -80px;
    font-size: 22px;
    font-style:italic;
    right: -125%;
  }
  #viaggio{}

  @media only screen and (max-width: 1024px) {

    .contentbox{
      padding-top: 180px;
    }
    .imgbackground{
      width: 90%;
    }
    .contentbox > p{
      top: 50px;
      font-size: 22px;
      margin: 0 20px;
      left: auto;
    }
    .badge{
      position: relative;
      top: 0px;
      left: -5%;
      right: auto;
    }
    .badge img{
      width: 50%;
      margin-right: 0;
      max-width: 200px;
    }
    .badge p{
      margin-right: auto;
      right:0;
    }
  }

</style>
