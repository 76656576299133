<template>
  <div class="content" @click="closeMenu()">
    <div id="info" class="anchor"></div>
    <div class="contentbox">
      <!-- <h1><span>Cerimonia</span></h1> -->
      <div id="center">
        <p class="date">25 Aprile 2022</p>
        <p class="centertext">Evento concluso!</p>
        <div class="daycounter">0</div>
      </div>
      <div id="left">
        <p class="toptext">Sarà celebrato presso</p>
        <img class="imgicon" src="../assets/church.png" />
        <p>Parrocchia di Sant'Eustorgio</p>
        <p style="font-size:22px;">Arcore</p>
        <button @click="showchurchinfo()">Info e posizione</button>
      </div>
      <div id="right">
        <p class="toptext">Festeggeremo poi presso</p>
        <img class="imgicon" src="../assets/champagne.png" />
        <p>Cascina Giovanni</p>
        <p style="font-size:22px;">Besana in Brianza</p>
        <button @click="showlocationinfo()">Info e posizione</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  data () {
    return {
    };
  },
  methods:{
    showchurchinfo: function(){
      this.$store.state.churchinfo = true;
      document.body.classList.add('noscroll');
    },
    showlocationinfo: function(){
      this.$store.state.locationinfo = true;
      document.body.classList.add('noscroll');
    },
    closeMenu: function(){
      this.$store.commit('closeMenu');
    }
  }
}
</script>

<style scoped>
  .contentbox{
    display: inline-block;
    text-align:center;
    width:100%;
    margin-bottom: 50px;
  }
  #info{
    position: relative;
    top: -100px;
  }
  .imgicon{
    width: 200px;
  }
  .daycounter{
    font-size: 50px;
    width: 150px;
    background: url('../assets/calendar.png') no-repeat;
    background-size: 150px;
    height: 120px;
    margin: 0 auto;
    padding-top: 50px;
    font-weight: 600;
  }

  #center{
    display: inline-block;
    margin: 0 auto;
    width: 33%;
    font-family: 'Poppins', sans-serif;
  }
  .date{
    font-size: 90px;
    margin-top: -20px;
    font-family: 'Niconne', cursive !important;
    width: 150%;
    margin-left: -20%;
  }
  .centertext{
    margin-top:100px;
    font-size: 22px;
  }
  #left{
    float:left;
    width: 33%;
    font-size: 24px;
  }
  button{
    border: none;
    border-radius: 5px;
    height: 50px;
    font-size: 20px;
    padding: 0 20px;
    font-style: italic;
  }
  button:hover{
    border: 3px solid cadetblue;
    cursor:pointer;
  }
  button:active{
    background-color:white;
  }

  #right{
    float:right;
    width: 33%;
    font-size: 24px;
  }
  .toptext{
    font-size:20px;
    font-style: italic;
    margin-top:100px;
    margin-bottom: 40px;
  }


  @media only screen and (max-width: 1024px) {

    #center{width:100%;}
    #left{width:100%;}
    #right{width:100%;}
    #info{
    }
    .date{
      margin-left: 0;
      width: 100%;
      font-size: 70px;
      margin-top: 80px;
    }

  }

</style>
