<template>
  <div class="moreinfo" v-if="$store.state.locationinfo">
    <div class="whiteback">
      <img class="closeimg" src="../assets/exit.png" @click="closeIMG()"/>
      <div class="content">
        <img class="parkimg" src="../assets/mappa_location.jpg" />
        <div class="buttons">
          <div class="singlebutton"><img height="20" src="../assets/direction.png" /><a href="https://goo.gl/maps/bVTujLvvX2EsFohu5" target="_blank">Naviga verso la Location</a></div>
          <div><p><img height="20" src="../assets/warning.png" />Gli ultimi 50 metri sono su strada sterrata</p></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Locationinfo',
  data () {
    return {  };
  },
  methods:{
    closeIMG: function(){
      this.$store.state.locationinfo = false;
      document.body.classList.remove('noscroll');
    }
  }
}
</script>

<style scoped>
  .moreinfo{
    background-color:rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  .whiteback{
    background: white;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    margin: 2.5% auto;
  }
  .content{
    padding: 30px;
    background-color: white;
    border-radius:10px;
  }
  .closeimg{
    width: 50px;
    position: relative;
    float: right;
    cursor: pointer;
  }
  .parkimg{
    width: 80%;
    border-radius: 30px;
    margin-bottom: 5%;
  }
  .buttons a{
    padding: 20px 0;
    text-decoration: none;
    color: black;
  }
  .singlebutton{
    height: auto;
    padding: 15px;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
    font-size:20px;
    background-color: antiquewhite;
    font-family: 'Poppins';
  }
  .buttons img{
    margin-right: 10px;
    vertical-align: middle;
  }

  @media only screen and (max-width: 1024px) {
    .whiteback{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0;
    }
    .closeimg{
      position: absolute;
      top: 0;
      right: 0;
    }
    .content{
      padding: 0;
    }
    .parkimg{
      width: 150%;
      margin: 0;
      border-radius:0;
      margin-left: -80px;
    }
    .buttons{
      margin-top: 10px;
    }
    .singlebutton{
      width:80%;
      display: inline-block;
      margin: 10px auto;
    }
  }

</style>
